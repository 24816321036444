import * as React from "react"
import { ProductCard } from "./product-card"

// Importing styles
import "./styles/product-listing.scss"

// Importing Bootstrap components
import { Col, Container, Row } from "react-bootstrap"

export function ProductListing({ products }) {
  return (
    <Row className="fade-in-fast">
      {products.map((p, index) => (
        <Col className="product-card" xs={12} sm={12} md={6} lg={6} xl={4}>
          <ProductCard product={p} key={p.id} eager={index === 0} />
        </Col>
      ))}
    </Row>
  )
}
