import * as React from "react"
import { graphql, Link } from "gatsby"

// Importing layout
import { Layout } from "../../../layouts/layout"
import { ProductListing } from "../../../components/product-listing"

// Importing components
import { Seo } from "../../../components/seo"
import slugify from "@sindresorhus/slugify"

// Importing styles
import "../styles/products-page.scss"

// Importing Bootstrap components
import { Button, Container, Row } from "react-bootstrap"

export default function ProductIndex({ data: { products } }) {
  return (
    <Layout>
      <Seo title="Shop" />
      <div className="products">
        <Container>
          <Row className="intro">
            <h1 className="shout">All Products</h1>
          </Row>
          <ProductListing products={products.nodes} />
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    products: allShopifyProduct(sort: { fields: title }) {
      nodes {
        ...ProductCard
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`
